























































import {Table} from "@/request/components/Table";
import {BaseRequest} from "@/request/BaseRequest";
import store from "@/store";

export default {
    props: {
        modal: {
            type: Object
        },
        search: {
            type: Array
        },
        showHeader: {
            type: Boolean,
            default: true
        },
        /**
         * 是否手动加载, 默认自动加载
         */
        manualLoad: {
            type: Boolean,
            default: false
        },
        columns: {
            type: Array,
            default() {
                return []
            }
        },
        showPage: {
            type: Boolean,
            default: true
        },
        drawerWidth: {
            type: Number,
            default: 600
        },
        formData: {
            type: Object,
            default() {
                return {}
            }
        },
        formRules: {
            type: Object,
            default() {
                return {}
            }
        },
        border: {
            type: Boolean,
            default: false
        },
        stripe: {
            type: Boolean,
            default: true
        },
        fixed: {
            type: String,
            default: 'right'
        }
    },
    data() {
        return {
            params: {},
            loading: false,
            exportLoading: false,
            showDrawer: false,
            resData: {},
            tableHeader: [],
            onSubmitLoading: false,
            pubOps: [],
            opsLoading: false,
            clientHeight: document.body.clientHeight,
            searchHeight: 0
        }
    },
    computed: {
        tableMaxHeight() {
            let self: any = this;
            return self.clientHeight - (self.searchHeight?self.searchHeight:10) - 210
        }
    },
    created() {
        let self: any = this;
        self.tableHeader = [...self.columns];
        self.tableHeader.forEach((j: any) => {
            j.resizable = true;
            j.align = j.align || 'center';
        });

        store.dispatch('getOperates').then((ops: any) => {
            self.pubOps = ops.pubOps;
            let tableOps = ops.tableOps;
            let width: number = 0;
            tableOps.map((j: any) => j.name).forEach((j: string) => width += j.length * 14 + 16);
            width += 36;

            if (tableOps.length > 0) {
                self.tableHeader.push({
                    title: '操作',
                    key: 'action',
                    fixed: self.fixed,
                    align: 'center',
                    width: width,
                    render: (h: any, p: any) => {
                        let row: any = p.row;
                        let tempOps: Array<object> =[];
                        let createElement: any = self.$createElement;
                        tableOps.forEach((j: any) => {
                            switch (j.key) {
                                case 'view':
                                    tempOps.push(
                                        h('Button', {
                                            props: {
                                                type: 'text',
                                                size: 'small'
                                            },
                                            on: {
                                                click() {
                                                    console.log('查看')
                                                }
                                            }
                                        }, j.name)
                                    );
                                    break;
                                case 'update':
                                    tempOps.push(
                                        h('Button', {
                                            props: {
                                                type: 'text',
                                                size: 'small'
                                            },
                                            on: {
                                                click() {
                                                    self.edit(p.row);
                                                }
                                            }
                                        }, j.name)
                                    );
                                    break;
                                case 'del':
                                    tempOps.push(
                                        h('Poptip', {
                                            props: {
                                                confirm: true,
                                                title: '确定要删除吗?',
                                                size: 'small',
                                                width: 200,
                                            },
                                            on: {
                                                'on-ok': () => {
                                                    self.del(p.row.id);
                                                }
                                            }
                                        }, [
                                            h('Button', {
                                                props: {
                                                    type: 'text',
                                                    size: 'small'
                                                }
                                            }, j.name)
                                        ])
                                    );
                                    break;
                                case 'onOff':
                                    tempOps.push(
                                        h('i-switch', {
                                            props: {
                                                ref: 't-switch',
                                                value: row.status === 1,
                                                size: 'small',
                                                loading: self.opsLoading,
                                                'before-change'() {
                                                    return new Promise(resolve =>{
                                                        self.opsLoading = true;
                                                        Table.onOffWithUrl(self.modal.requestPath, {id: row.id, status: !row.status?1:0}).then((body: any) => {
                                                            self.opsLoading = false;
                                                            if (body.code !== 0) {
                                                                resolve(new Promise(() =>{}));
                                                            } else {
                                                                self.flush();
                                                            }
                                                        })
                                                    })
                                                }
                                            },
                                            style: {
                                                margin: '0 7px'
                                            },
                                            on: {
                                                'on-change'(value: any) {
                                                    /*Table.onOffWithUrl(self.modal.requestPath, {id: row.id, status: !value?0:1}).then((body: any) => {
                                                    })*/
                                                }
                                            }
                                        })
                                    );
                                    break;
                                case 'print':
                                    tempOps.push(
                                        createElement('Button', {
                                            props: {
                                                type: 'text',
                                                size: 'small'
                                            },
                                            directives: [
                                                {
                                                    name: 'print',
                                                    value: '#printMe',
                                                    // arg: ''
                                                }
                                            ],
                                            on: {
                                                click() {
                                                    console.log(`on-${j.key}`, row);
                                                    self.$emit(`on-${j.key}`, row);
                                                }
                                            }
                                        }, j.name)
                                    );
                                    break;
                                default:
                                    tempOps.push(
                                        h('Button', {
                                            props: {
                                                type: 'text',
                                                size: 'small'
                                            },
                                            on: {
                                                click() {
                                                    console.log(`on-${j.key}`, row);
                                                    self.$emit(`on-${j.key}`, row);
                                                }
                                            }
                                        }, j.name)
                                    );
                                    break;
                            }
                        });
                        return h('div', {}, tempOps);
                    }
                });
            }
        })
        if (!self.manualLoad) {
            self.loadData()
        }
    },
    methods: {
        loadData() {
            let self: any = this;
            return new Promise(resolve => self.loadDataWithNotInit().then((data: any) => self.initData(data)));
        },
        loadDataWithNotInit() {
            let self: any = this;
            return new Promise(resolve => {
                self.loading = true;
                console.log(self.modal)
                Table.listWithUrl(self.modal.requestPath, self.params).then((body: any) => {
                    self.loading = false;
                    if (body['code'] === 0) {
                        resolve(body.data);
                    }
                })
            })
        },
        initData(data: any) {
            let self: any = this;
            self.resData = data;
            if (self.resData['columns']) {
                console.warn('动态表头', self.resData['columns']);
                self.tableHeader = [...self.columns, ...self.resData['columns']];
            }
        },
        add() {
            let self: any = this;
            self.$parent.$refs['el-form'].resetFields();
            self.showDrawer = true;
            self.$emit('on-add');
        },
        edit(row: any) {
            let self: any = this;
            self.$parent.$refs['el-form']?.resetFields();
            if (self.$parent.$refs['el-form']) {
                self.showDrawer = true;
            }
            self.$emit('on-edit', {...row});
        },
        flushById(id: string, expand?: boolean) {
            let self: any = this;
            Table.findByIdWithUrl(self.modal.requestPath, id).then((body: any) => {
                if (body['code'] === 0) {
                    let data = body.data;
                    let index = self.resData.list.findIndex((value: any) => value.id === id);
                    let _expanded = self.resData.list[index]._expanded;
                    self.resData.list.splice(index, 1, data);
                    self.resData.list[index]._expanded = expand? expand: _expanded;
                }
            })
        },
        del(id: string) {
            let self: any = this;
            Table.delWithUrl(self.modal.requestPath, [id]).then((body: any) => {
                if (body['code'] === 0) {
                    self.flush();
                }
            })
        },
        doSubmit(params: any) {
            let self: any = this;
            return new Promise(resolve => {
                self.doSubmitWithNotFlush(params).then((data: any) => {
                    self.flush();
                    resolve(data);
                })
            })
        },
        doSubmitWithNotFlush(params: any) {
            let self: any = this;
            return new Promise((resolve, reject) => {
                self.onSubmitLoading = true;
                self.modal.save(params).then((body: any) => {
                    self.showDrawer = body['code'] !== 0;
                    self.onSubmitLoading = false;
                    if (body['code'] === 0) {
                        resolve(body.data);
                    } else {
                        reject();
                    }
                })
            })
        },
        doSubmitWithValidate(params: any) {
            let self: any = this;
            return new Promise(((resolve, reject) => {
                self.$parent.$refs['el-form'].validate((valid: boolean) => {
                    if (valid) {
                        self.doSubmit(params).then((data: any) => resolve(data)).catch(() => reject());
                    }
                })
            }))
        },
        doImport() {

        },
        doExport() {
            let self: any = this;
            self.exportLoading = true;
            let data: any = self.assignObject(self.params);
            delete data.pageIndex;
            delete data.pageSize;
            console.log('导出条件 ', data);
            Table.doExport({
                url: `${self.modal.requestPath}/export`,
                data: data,
                fileName: `${store.getters.comp.name}.xls`
            }).then(() => self.exportLoading = false);
        },
        flush() {
            let self: any = this;
            return new Promise(resolve => self.loadData().then((data: any) => resolve(data)));
        },
        pageSizeChange(pageSize: number) {
            let self: any = this;
            self.params['pageSize'] = pageSize;
            self.flush()
        },
        changePage(pageIndex: number) {
            let self: any = this;
            self.params['pageIndex'] = pageIndex;
            self.flush()
        },
        exportData(option: any) {
            let self: any = this;
            self.$refs['data-table'].exportCsv(option)
        },
        reset() {
            let self: any = this;
            self.loading = false;
            self.resData = {}
        },
        onExpand(row: any, status: boolean) {
            let self: any = this;
            let index = self.resData.list.findIndex((value: any) => value.id === row.id);
            self.resData.list[index]._expanded = status;
        },
        changeSubmitLoading() {
            let self: any = this;
            self.onSubmitLoading = !self.onSubmitLoading;
        },
        onSearch(params: any) {
            let self: any = this;
            if (params === null) return;
            let tempParams: any = Object.assign({
                pageSize: self.params.pageSize,
                pageIndex: 1
            }, ...params);
            self.params = tempParams;
            console.log('查询条件', JSON.stringify(tempParams));
            self.flush();
        },
        hideDrawer() {
            let self: any = this;
            self.showDrawer = false;
        }
    }
}
